@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  background-color: #111217;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav {
    padding: 2px 0;
    background-color: #1a1e2c;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}


.nav-links {
    list-style-type: none;
    margin: auto;
}

.nav-links a:hover {
    color: #dc9136;
}
  
.nav-links a {
    float: left;
    position: relative;
    Transform: translateY(10%);
    color: #f2f2f2;
    padding: 1px 2px;
    padding-bottom: 30px;
    padding-top: 10px;
    font-size: 18px;
    align-content: center;
    text-decoration: underline;
}  

@media all and (min-width: 375px) {
    .nav-links a {
        padding: 1px 4px;
        margin-right: 5px;
        text-decoration: none;
        align-content: center;
    } 
}

  
@media all and (min-width: 700px) {
    .nav-links a {
        padding: 1px 10px;
        padding-bottom: 30px;
        padding-top: 10px;
        margin-right: 5px;
        font-size: 1.4rem;
      }

      .nav-links a:first-of-type {
          padding-left: 50px;
      }
}


@media all and (min-width: 1024px) {
    .nav-links a {
        padding: 1px 0px;
        padding-bottom: 30px;
        padding-top: 10px;
        margin-right: 5px;
        font-size: 1.4rem;
    }

    .nav-links a:first-of-type {
        margin-left: 100px;
    }
}

.landing_page {
    position: absolute;
    overflow: clip;
    width: 100vw;
    height: 100vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}



.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.header_wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 100px;
}

.title {
    width: 300px;
}

.title h1 {
    width: 100%;
    line-height: 1.2;
    font-size: 2rem;
    padding-left: 10px;
    font-family: 'Roboto Slab', serif;
}


.fire {
    width: 180px;
}

@media all and (min-width: 375px) {
    
    .header_wrapper {
        padding-bottom: 150px;
    }
    
    .title {
        width: 300px;
    }
    
    .title h1 {
        width: 100%;
        line-height: 1.2;
        font-size: 2rem;
        font-family: 'Roboto Slab', serif;
    }
    
    
    .fire {
        width: 250px;
        margin-top: 10px;
    }
    
}

@media all and (min-width: 700px) {
   
.title {
    width: 500px;
    margin-right: 120px;
}

.title h1 {
    line-height: 1.2;
    font-size: 2.3rem;
    font-family: 'Roboto Slab', serif;
}


.fire {
    width: 400px;
    margin-top: 90px;
    margin-left: 40px;
}
}

@media all and (min-width: 1024px) {

    .title {
        width: 400px;
        margin-right: 0px;
        margin-bottom: 150px;
    }
    
    .title h1 {
        line-height: 1.2;
        font-size: 2.3rem;
        font-family: 'Roboto Slab', serif;
    }
    
    
    .fire {
        width: 500px;
        margin-top: 150px;
        margin-left: 40px;
    }
   
}

@media all and (min-width: 1024px) {

    .title {
        width: 450px;
        margin-right: 50px;
        margin-bottom: 200px;
    }
    
    .title h1 {
        line-height: 1.2;
        font-size: 2.3rem;
        font-family: 'Roboto Slab', serif;
    }
    
    
    .fire {
        width: 450px;
        margin-top: 200px;
        margin-left: 40px;
    }
   
}


.about_container {
    color: white;
    font-family: 'Roboto Slab', serif;
}

.about_landing_container {
    display: flex;
    flex-direction: column;
}

.wrapper {
    width: 100vw;
    height: 100vh;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.about_photo{
    width: 300px;
    margin-bottom: 150px;
    margin-top: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.about {
    text-align: center;
}

.arrow_down_btn {
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    transition: ease-in .1s;
}

.arrow_down_btn:hover{
    color: sienna;
}

.arrow_down_btn .arrow_down {
    width: 40px;
    height: 40px;
}

.about_desc {
    min-width: 300px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: .9rem;
    margin-bottom: 80px;
    max-width: 600px;
}

@media all and (min-width: 375px) {
    .about_desc {
        font-size: 1rem;
    }
}

@media all and (min-width: 700px) {
    .about_desc {
        font-size: 1.3rem;
    }

    .about_photo{
        width: 400px;
    }

}
.project_page {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: white;
    font-family: 'Roboto Slab', serif;
}
.project_container {
    width: 100vw;
    height: 100vh;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}


.project_container img {
    width: 180px;
    border-radius: 8px 8px 8px 8px;
}

.project_description {
    width: 300px;
}
.project_description h3 {
    font-size: 1rem;
}
.project_description p {
    font-size: .9rem;
    width: 100%;
}

.project_description p a {
    color: green; 
}

.project_description p a:hover {
    color: rgb(50, 139, 50); 
}

@media all and (min-width: 375px) {

    .project_container img {
        width: 300px;
        border-radius: 8px 8px 8px 8px;
    }
    
    .project_description {
        width: 300px;
    }

    .project_description h3 {
        font-size: 1.3rem;
    }

    .project_description p {
        font-size: .9rem;
        width: 100%;
    }
    
    .project_description p a {
        color: green; 
    }
    
    .project_description p a:hover {
        color: rgb(50, 139, 50); 
    }

}

@media all and (min-width: 700px) {
    
    .project_container img {
        width: 400px;
        border-radius: 8px 8px 8px 8px;
    }

    .project_description {
        padding-top: 30px;
        width: 400px;
        padding-bottom: 50px;
    }

    .project_description h3 {
        font-size: 1.7rem;
    }

    .project_description p {
        font-size: 1.1rem;
    }
}

@media all and (min-width: 1024px) {
    
    .project_container img {
        width: 500px;
        border-radius: 8px 8px 8px 8px;
    }

    .project_description {
        padding-top: 30px;
        width: 500px;
        padding-bottom: 50px;
    }

    .project_description h3 {
        font-size: 1.7rem;
    }

    .project_description p {
        font-size: 1.1rem;
    }
}
.contact_container {
    width: 100vw;
    height: 100vh;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    
}

.img_wrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 100px;
}
.email_container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    color: white;
    transition: ease-in .2s;
}

.email_container p {
    transition: ease-in .2s;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    margin-top: 40px;
}


.contact_container img {
    width: 100px;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media all and (min-width: 375px) {

    .contact_container img {
        width: 120px;
    }

    .email_container p {
        margin-top: 40px;
    }
}

@media all and (min-width: 700px) {
    .contact_container img {
        width: 200px;
        margin-right: 20px;
        margin-left: 20px;
    }
}

@media all and (min-width: 1024px) {
    .contact_container img {
        width: 200px;
        margin-right: 20px;
        margin-left: 20px;
    }

    .img_wrapper {
        flex-direction: row;
        margin-bottom: 60px;
    }
}

@media all and (min-width: 1024px) {
    .contact_container img {
        margin-right: 40px;
        margin-left: 40px;
    }
}
.missing-page-title {
    margin: 0;
}

.center {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    align-items: center;
    height: 150px;
    margin-top: 100px;
    margin-left: 10%;
}
.App {
  margin: auto;

}

html {
  scroll-behavior: smooth;
}

