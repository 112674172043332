.about_container {
    color: white;
    font-family: 'Roboto Slab', serif;
}

.about_landing_container {
    display: flex;
    flex-direction: column;
}

.wrapper {
    width: 100vw;
    height: 100vh;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.about_photo{
    width: 300px;
    margin-bottom: 150px;
    margin-top: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.about {
    text-align: center;
}

.arrow_down_btn {
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    transition: ease-in .1s;
}

.arrow_down_btn:hover{
    color: sienna;
}

.arrow_down_btn .arrow_down {
    width: 40px;
    height: 40px;
}

.about_desc {
    min-width: 300px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: .9rem;
    margin-bottom: 80px;
    max-width: 600px;
}

@media all and (min-width: 375px) {
    .about_desc {
        font-size: 1rem;
    }
}

@media all and (min-width: 700px) {
    .about_desc {
        font-size: 1.3rem;
    }

    .about_photo{
        width: 400px;
    }

}