.missing-page-title {
    margin: 0;
}

.center {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    align-items: center;
    height: 150px;
    margin-top: 100px;
    margin-left: 10%;
}