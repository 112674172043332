.contact_container {
    width: 100vw;
    height: 100vh;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    
}

.img_wrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 100px;
}
.email_container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    color: white;
    transition: ease-in .2s;
}

.email_container p {
    transition: ease-in .2s;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    margin-top: 40px;
}


.contact_container img {
    width: 100px;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media all and (min-width: 375px) {

    .contact_container img {
        width: 120px;
    }

    .email_container p {
        margin-top: 40px;
    }
}

@media all and (min-width: 700px) {
    .contact_container img {
        width: 200px;
        margin-right: 20px;
        margin-left: 20px;
    }
}

@media all and (min-width: 1024px) {
    .contact_container img {
        width: 200px;
        margin-right: 20px;
        margin-left: 20px;
    }

    .img_wrapper {
        flex-direction: row;
        margin-bottom: 60px;
    }
}

@media all and (min-width: 1024px) {
    .contact_container img {
        margin-right: 40px;
        margin-left: 40px;
    }
}